body,html {
  width: 100%;
}

#root {
  width: 100%;
}

main {
  width: 100%;
}

.App {
  width: 100%;
}