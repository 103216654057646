.search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.search-form {
    display: flex;
    flex-direction: row;
    background-color: #758173;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 1rem;
}

.search-form-input-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.search-results-container {
    width: 100%;
}

