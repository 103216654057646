.recipe-search-result {
    background: #f1f1f1;
    padding: 0.75rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
    color: black;
}

.recipe-search-result-title {
    text-decoration: none;
}